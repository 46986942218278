import { render, staticRenderFns } from "./AnnouncementDialog.vue?vue&type=template&id=19e2c91c&scoped=true"
import script from "./AnnouncementDialog.vue?vue&type=script&lang=js"
export * from "./AnnouncementDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19e2c91c",
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QCardSection,QChip,QIcon,QBtn,QCardActions});qInstall(component, 'directives', {ClosePopup});
